<template lang='pug'>
Home(
  :aprInitial='stringToNumber(apr)'
  :downPaymentInitial='stringToNumber(price) * 20 / 100'
  :downPaymentPercentInitial='20'
  :priceInitial='stringToNumber(price)'
  :termInitial='30'
)
</template>

<script>
import Home from '../components/Home.vue';

import { stringToNumber } from '@thomasphan/vue-components/src/functions/stringToNumber';

const apr =
{
  required: true
};

const price =
{
  required: true
};

export default
{
  components:
  {
    Home
  },
  methods:
  {
    stringToNumber
  },
  props:
  {
    apr,
    price,
  },
};
</script>